<template>
  <div>
    <h3 class="title text-center">
      测评前提示
    </h3>
    <div class="box text-center">
      <Player :url="url" />
    </div>
    <div class="panel">
      <div class="note text-center">
        温馨提示
      </div>
      <p class="text">
        在测评过程中可能涉及到一些家用测评工具，如没有，可用相似工具代替。
      </p>
    </div>
  </div>
</template>

<script>
import { toolRemindVideo } from '@/api';

export default {
  components: {
    Player: () => import('@/components/Player/index.vue')
  },
  data() {
    return {
      url: ''
    };
  },
  created() {
    this.toolRemindVideo();
  },
  methods: {
    toolRemindVideo() {
      const params = {
        phone: localStorage.getItem('phone')
      };
      toolRemindVideo(params).then((res) => {
        this.url = res.data?.toolRemindVideoUrl;
      }, (err) => {
        this.url = '';
        this.$message.warning(err.msg || '视频获取失败');
      });
    }
  },
};
</script>

<style lang="less" scoped>
.title{
  font-size: 18px;
  line-height: 20px;
  color: #333;
}
.box{
  height: 218px;
  // line-height: 218px;
  box-shadow: 0 0 1px gray;
  margin-bottom: 16px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.panel{
  // border: 1px solid #ccc;
  // padding: 15px 15px 0;
  margin-bottom: 15px;
  border-radius: 4px;
  text-align: center;
  p,div{
    font-weight: bold;
  }
}
.note,.text{
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}
</style>
